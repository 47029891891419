import logo from "./Logo2.png";
import p1sLogo from "./p1s.jpg";
import sv02Logo from "./sv02.jpg";
import resinLogo from "./resin.png";
import "./App.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Skeleton,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

function App() {
  const technologies = [];
  //   technologies.push({
  //     name: "Filament",
  //     description: "TBD Description of filament printing.",
  //   });
  //   technologies.push({
  //     name: "Resin",
  //     description: "TBD Description of resin printing.",
  //   });

  const printers = [];
  printers.push({
    name: "BambuLab P1S",
    logo: p1sLogo,
    description:
      "... is a high speed filament printer. Combined with one AMS it is capable of printing up to 4 filaments.",
  });
  printers.push({
    name: "Resin (coming later)",
    logo: resinLogo,
    description: "",
  });

  //   console.log(printers);

  const [rotatingClass, setRotatingClass] = useState("");

  function toggleRotate() {
    var rotating = rotatingClass !== "";
    setRotatingClass(rotating ? "" : "app-logo-animated");
    console.log(rotatingClass);
  }

  return (
    <div className="App">
      <h2>🚧 Page under construction 🚧</h2>
      <h3>Release soon</h3>
      <p>
        Requests on following mail:{" "}
        <a href="mailto:info@th3slickprind.de">info@th3slickprind.de</a>
      </p>
      <img
        className={`App-logo logo2 ${rotatingClass}`}
        src={logo}
        alt="Logo.png"
      />
      <br />
      <br />
      <p className="show-pointer" onClick={(e) => toggleRotate()}>
        TH3 SLICK PRIND ©2024
      </p>
      {/* <h3>Technologies</h3> */}
      {technologies.map((item) => (
        <Accordion>
          <AccordionSummary>{item.name}</AccordionSummary>
          <AccordionDetails>{item.description}</AccordionDetails>
        </Accordion>
      ))}
      {/* <h3>Our machines</h3> */}
      <Stack
        direction="row"
        spacing={3}
        useFlexGap
        sx={{
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {printers.map((item) => (
          <Card sx={{ width: 275 }}>
            <CardContent>
              <img className="printer-logo" src={item.logo} />
              <Typography variant="h5" component="div">
                {item.name}
              </Typography>
              <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                {item.description}
              </Typography>
            </CardContent>
            {/* <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions> */}
          </Card>
        ))}
      </Stack>
    </div>
  );
}

export default App;
